import React from "react";
import Column from "~/column";
import Container from "~/container";
import Layout from "~/layout";
import SEO from "~/seo";

const ImprintPage = () => (
    <Layout>
        <Container spacing={true}>
            <Column>
                <h1>Impressum</h1>
            </Column>
            <div className="md:flex md:items-start">
                <Column className="md:w-1/2">
                    <p>
                        <strong>Büro Schriever GmbH &amp; Co. KG</strong>
                        <br />
                        Bächterhof 4<br />
                        58553 Halver
                    </p>

                    <p>
                        Telefon: 02353 666 54 66
                        <br />
                        Telefax: 02353 666 54 64
                        <br />
                        E-Mail: <a href="mailto:info@bueroschriever.de">info@bueroschriever.de</a>
                    </p>
                </Column>
                <Column className="md:w-1/2">
                    <dl>
                        <dt>Vertreten durch</dt>
                        <dd>Lutz Schriever</dd>

                        <dt>Registereintrag</dt>
                        <dd>
                            Amtsgericht Iserlohn
                            <br />
                            HRA 4013
                        </dd>

                        <dt>Umsatzsteuer-ID</dt>
                        <dd>DE237317384</dd>

                        <dt>Persönlich haftende Gesellschafterin</dt>
                        <dd>
                            Büro Schriever Verwaltungs-GmbH
                            <br />
                            Amtsgericht Iserlohn
                            <br />
                            HRB 5039
                        </dd>

                        <dt>Geschäftsführer</dt>
                        <dd>Lutz Schriever</dd>

                        <dt>Design &amp; Realisation</dt>
                        <dd>
                            <a
                                className="inline-flex space-x-1 group hover:no-underline"
                                href="https://kolossal.io"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <span>kolossal</span>
                                <span className="transition translate-y-1 opacity-0 transform-gpu group-hover:opacity-100 group-hover:translate-y-0">
                                    🫶
                                </span>
                            </a>
                        </dd>
                    </dl>
                </Column>
            </div>
        </Container>
    </Layout>
);

export const Head = () => (
    <SEO
        title="Impressum"
        description="Informationen über Büro Schriever GmbH &amp; Co. KG als Betreiber dieser Website."
    />
);

export default ImprintPage;
